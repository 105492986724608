export default defineNuxtRouteMiddleware((to) => {
  const appStore = useAppStore()

  const category = appStore.merchant.categories.find(
    (category) => category?.slug == to.params.slug,
  )

  if (!category) {
    return abortNavigation()
  }

  if (
    to.params.childSlug &&
    !category.children?.some((category) => category.slug == to.params.childSlug)
  ) {
    return abortNavigation()
  }
})
